<template>
  <app-module-view>
    <template slot="body">
      <app-preloader-full v-if="callingAPI" :stroke-width="5"></app-preloader-full>
      <app-article-filter></app-article-filter>
      <div>
        <div v-if="autoLogoutArticle" class="alert alert-warning logout-message">
          <i class="fa fa-exclamation-circle"></i>
          {{ $t('article.auto_logout_text') }} <strong>{{ autoLogoutArticle.field.title }}</strong>.
          <router-link
            tag="a"
            class="btn btn-default btn-sm"
            :to="{ name: 'article_edit', params: { id: autoLogoutArticle.id }}"
            v-if="autoLogoutArticle.setting.status === 'draft'"
            data-test="article_list_btn_edit"
          >
            <i class="fa fa-edit"></i> {{ $t('buttons.edit') }}
          </router-link>
          <router-link
            tag="button"
            class="btn btn-default btn-sm"
            :to="{ name: 'article_detail', params: { id: autoLogoutArticle.id }}"
            v-if="autoLogoutArticle.setting.status === 'draft'"
            data-test="article_list_btn_detail"
          >
            <i class="fa fa-info"></i>
          </router-link>
        </div>
        <div>
          <div v-if="error" class="m-t-10">
            {{ $t('article.foundAnError') }}
          </div>
          <div v-if="callingAPI === false && articles.length === 0" class="m-t-10">
            <p class="alert alert-info">
              {{ $t('result_not_found') }}
            </p>
          </div>
          <TableArticleList
            v-if="articles.length"
            :articles="articles"
            :is-allowed-to-edit="isAllowedToEdit"
            :total-count-of-articles="totalCount"
            :current-page="currentPage"
            @current-page-changed="currentPage = $event"
            :page-count="pageCount"
            :click-handler="setPageAndGetArticles"
          />
        </div>
      </div>
      <ArticleActivitiesChecker />
    </template>
  </app-module-view>
</template>

<script>
import Store from '@/store'
import ModuleView from '../../components/ModuleView'
import { mapState } from 'vuex'
import config from '../../config'
import ArticleFilter from '../../components/article/ArticleFilter'
import PreloaderFull from '../../components/preloader/PreloaderFull'
import ArticleActivitiesChecker from '@/components/article/ArticleActivitiesChecker'
import NotifyService from '../../services/NotifyService'
import CoreApi from '../../api/core'
import ArticleList from '@/components/mixins/ArticleList'
import TableArticleList from '@/components/table/TableArticleList'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'ArticleListView',
  mixins: [ArticleList],
  data () {
    return {
      error: null,
      currentPage: 1,
      dataLoaded: false,
      selectedArticle: {},
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.ARTICLE_PERMISSIONS
    }
  },
  computed: {
    ...mapState([
      'articles',
      'callingAPI'
    ]),
    currentUser () {
      return this.$store.getters['user/currentUser']
    },
    articles () {
      return this.$store.getters['article/list']
    },
    totalCount () {
      return this.$store.getters['article/totalCount']
    },
    pageCount () {
      return Math.ceil(this.$store.getters['article/totalCount'] / config.article.list.limit)
    },
    page () {
      return this.$store.getters['article/page']
    },
    autoLogoutArticle () {
      return this.$store.getters['article/autoLogoutArticle']
    }
  },
  components: {
    TableArticleList,
    appModuleView: ModuleView,
    appArticleFilter: ArticleFilter,
    appPreloaderFull: PreloaderFull,
    ArticleActivitiesChecker
  },
  methods: {
    getArticles () {
      this.$store.dispatch('article/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetArticles (page) {
      this.$store.commit('article/setPage', page)
      this.getArticles()
    },
    hasPermissionsForSite (permission, articleSiteName) {
      return this.$store.getters['user/hasPermission'](permission, articleSiteName)
    },
    isEditButtonVisible (article) {
      const articleSiteId = article.site
      const articleSiteName = this.$store.getters['site/siteById'](articleSiteId)?.name
      return this.hasPermissionsForSite(this.requiredPermissions.editButton, articleSiteName) &&
        ['draft', 'review'].includes(article.setting.status)
    },
    isAllowedToEdit (article) {
      if (article.setting.status === 'review') {
        this.changeArticleStatusToDraft(article)
        return
      }
      const currentUser = this.$store.getters['user/currentUser']
      const activity = this.$store.getters['activity/articleActivityById'](article.documentId)
      if (activity) {
        if (activity.userId === currentUser.id) {
          this.$router.push('/article/' + article.id + '/edit')
        } else {
          this.$router.push('/article/' + article.id)
        }
      } else {
        this.$router.push('/article/' + article.id + '/edit')
      }
    },
    changeArticleStatusToDraft (article) {
      CoreApi().patch('article/' + article.id + '/status/draft')
        .then((response) => {
          if (response.status !== 200) {
            NotifyService.setErrorMessage(response.statusText)
            return
          }
          NotifyService.setSuccessMessage(this.$t('article.notify.article_status_changed_to_draft'))
          this.$router.push('/article/' + article.id + '/edit')
        })
        .catch((error) => {
          if (error.response.status === 403) {
            NotifyService.setErrorMessage(this.$t('article.notify.article_status_changed_to_draft_forbidden'))
          } else {
            NotifyService.setErrorMessage(error)
          }
        })
    }
  },
  updated () {
    this.currentPage = this.page
  },
  created () {
    this.currentPage = this.page
  },
  beforeDestroy () {
    this.$store.commit('article/setAutoLogoutArticle', null)
  },
  beforeMount () {
    this.$store.commit('article/setView', 'minimal')
  },
  beforeRouteEnter (to, from, next) {
    if (!from.path.startsWith('/article/')) {
      Store.commit('article/setPage', 1)
    }
    next()
  }
}
</script>

<style lang="scss" scoped>
  .article-types {
    font-size: 18px;
    color: #181c22;
  }

  .card-body {
    padding: 0 0.5rem;
  }

  .logout-message {
    margin-top: 10px;
  }
</style>
